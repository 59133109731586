import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { CustomRoute } from '@core/routing/types/custom-route';
import { IsAuthenticatedGuard } from '@core/authentication/guards/is-authenticated/is-authenticated.guard';

const routes: CustomRoute[] = [
    {
        path: '',
        component: AlertsComponent,
        canActivate: [IsAuthenticatedGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})
export class AlertsRoutingModule {}
