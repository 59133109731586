import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { AppAlert } from '@shared/api';
import { ModalComponent } from '@common/components/modal/modal.component';
import { ScrollbarDirective } from '@common/directives/scroll-bar/scroll-bar.directive';
import { CompileHtmlDirective } from '../../../html/directives/compile-html/compile-html.directive';
import { NgIf } from '@angular/common';
import { SurveyComponent } from '@shared/live-polling-surveys/components/survey/survey.component';

@Component({
    selector: 'cc-alert-popup-modal',
    templateUrl: './alert-popup-modal.component.html',
    styleUrls: ['./alert-popup-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ModalComponent, ScrollbarDirective, CompileHtmlDirective, NgIf, SurveyComponent, IonContent]
})
export class AlertPopupModalComponent {
    @Input()
    public alert: AppAlert;

    constructor(
        private modalController: ModalController,
        private alertsFacadeService: AlertsFacadeService
    ) {}

    public dismiss(): void {
        this.alertsFacadeService.dispatch(AlertsActions.markAsRead({ alert: this.alert }));
        this.modalController.dismiss();
    }

    public pollComplete(): void {
        this.dismiss();
    }
}
