import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { AlertFilter } from '@features/alerts/enums/alerts-filter.enum';
import { CheckboxCustomEvent, IonButton, IonCheckbox, IonItem } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'cc-alert-list-filter-panel',
    templateUrl: './alert-list-filter-panel.component.html',
    styleUrls: ['./alert-list-filter-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, IonItem, IonCheckbox, IonButton]
})
export class AlertListFilterPanelComponent {
    @Input()
    public disabled: boolean;

    @Input()
    public currentItems: ExpandedApiDrivenNotification[];

    @Output()
    public setFilter = new EventEmitter<AlertFilter>();

    @Output()
    public markAllRead = new EventEmitter<void>();

    @Output()
    public archiveAll = new EventEmitter<void>();

    public allRead: boolean = true;
    public allUnread: boolean = true;

    public onToggleUnread($event: Event): void {
        this.allUnread = ($event as CheckboxCustomEvent).detail.checked;

        this.notifyFilterChange();
    }

    public onToggleRead($event: Event): void {
        this.allRead = ($event as CheckboxCustomEvent).detail.checked;

        this.notifyFilterChange();
    }

    public notifyFilterChange(): void {
        const filterMode = this.determineFilterMode({ read: this.allRead, unread: this.allUnread });

        this.setFilter.emit(filterMode);
    }

    private determineFilterMode({ read, unread }: { read: boolean; unread: boolean }): AlertFilter {
        if (unread && !read) {
            return AlertFilter.Unread;
        } else if (read && !unread) {
            return AlertFilter.Read;
        } else {
            return AlertFilter.All;
        }
    }
}
