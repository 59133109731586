import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeIn, fadeOut } from '@common/animations/animations';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '@common/components/loading-spinner/loading-spinner.component';
import { SurveyComponent } from '@shared/live-polling-surveys/components/survey/survey.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonButton, IonCard, IonCardContent } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-alert-survey-container',
    templateUrl: './alert-survey-container.component.html',
    styleUrls: ['./alert-survey-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn(), fadeOut()],
    imports: [NgIf, LoadingSpinnerComponent, SurveyComponent, TranslateModule, IonCard, IonCardContent, IonButton]
})
export class AlertSurveyContainerComponent {
    @Input()
    public pollGroupId: number;

    public loadSurvey: boolean = false;

    public loadSurveyClicked(): void {
        this.loadSurvey = true;
    }
}
