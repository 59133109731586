import { Injectable } from '@angular/core';
import { DialogIds } from '@core/modal/constants/dialog-ids.constant';
import { ModalController } from '@ionic/angular/standalone';
import { AppAlert } from '@shared/api';
import { AlertPopupModalComponent } from '../components/alert-popup-modal/alert-popup-modal.component';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    constructor(private modalController: ModalController) {}

    public async showAlertModalDialog(alert: AppAlert): Promise<any> {
        const modal = await this.modalController.create({
            component: AlertPopupModalComponent,
            id: DialogIds.PopupAlertModal,
            componentProps: {
                alert
            },
            backdropDismiss: false
        });

        await modal.present();

        const result = await modal.onDidDismiss();
        return result;
    }

    public filterExpiredAlerts(alerts: AppAlert[]): AppAlert[] {
        if (!alerts || alerts.length === 0) {
            return [];
        }

        return alerts.filter((alert) => !this.hasAlertExpired(alert));
    }

    public hasAlertExpired(alert: AppAlert): boolean {
        if (!alert.end) {
            return false;
        }

        const expires = DateTime.fromISO(alert.end).toUTC();
        const current = DateTime.now().toUTC();

        return expires < current;
    }
}
