<div class="filter-panel__container">
    <div class="filter-panel__inputs">
        <ion-item lines="none">
            <ion-checkbox
                slot="start"
                labelPlacement="end"
                [disabled]="disabled"
                [checked]="allUnread"
                (ionChange)="onToggleUnread($event)"
            >
                {{ 'ALERTS_ALL_UNREAD' | translate }}
            </ion-checkbox>
        </ion-item>
        <ion-item lines="none">
            <ion-checkbox
                slot="start"
                labelPlacement="end"
                [disabled]="disabled"
                [checked]="allRead"
                (ionChange)="onToggleRead($event)"
            >
                {{ 'ALERTS_ALL_READ' | translate }}
            </ion-checkbox>
        </ion-item>
    </div>
    <div class="filter-panel__actions">
        <ion-button [disabled]="disabled" (click)="markAllRead.emit()">{{
            'ALERTS_MARK_ALL_READ' | translate
        }}</ion-button>
        <ion-button [disabled]="disabled" (click)="archiveAll.emit()">{{
            'ALERTS_ARCHIVE_ALL' | translate
        }}</ion-button>
    </div>
</div>
